// Dependencies
import { action, makeAutoObservable } from 'mobx';

class ErrorStore {
	error = null;

	constructor() {
		makeAutoObservable(this);
	}

	handle = action((error) => {
		this.error = error;
	});

	clear = action(() => {
		this.error = null;
	});
}

export default new ErrorStore();
