import React from 'react';
import styles from './ErrorNotification.module.scss';

const ErrorNotification = ({ message, onClick }) => (
	<div
		role="alert"
		className={styles['error-notification']}
		onClick={onClick}
	>
		{message}
	</div>
);

export default ErrorNotification;
