import HubClient from '@anephenix/hub/lib/client';

const url = process.env.NEXT_PUBLIC_WS_URL;
const hubClient = new HubClient({
	url,
	// See note below
	storageType: 'sessionStorage',
});

/**
 * StorageType note
 * ----------------
 *
 * We have specified the storageType of sessionStorage, so that we can ensure
 * that actions taken in one browser window (e.g. subscribe/unsubscribe
 * to/from a channel) will not affect other browser windows.
 *
 * We saw that if we were viewing the same dashboard in two tabs, and closed
 * one tab or navigated away from that dashboard on it, then the unsubscribe
 * would affect not only that tab, but other open tabs. This is because the
 * clientId was stored in localStorage across all tabs.
 *
 * To solve this, the clientId is unique per tab.
 */

export default hubClient;
