// Dependencies
import { action, makeAutoObservable } from 'mobx';

class MessageStore {
	message = null;

	constructor() {
		makeAutoObservable(this);
	}

	handle = action((message) => {
		this.message = message;
	});

	clear = action(() => {
		this.message = null;
	});
}

export default new MessageStore();
