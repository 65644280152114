import React from 'react';
import styles from './Logo.module.scss';
import Link from 'next/link';

const Logo = () => (
	<Link href="/">
		<div style={{ fontWeight: 'bold', cursor: 'pointer' }} id={styles.logo}>
			<div id={styles.status} />
			<div id={styles['logo-text']}>Dashku</div>
		</div>
	</Link>
);

export default Logo;
