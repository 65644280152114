// Stores
import dashboardsStore from './dashboardsStore';
import errorStore from './errorStore';
import messageStore from './messageStore';
import widgetsStore from './widgetsStore';
import { enableStaticRendering } from 'mobx-react';

const isServer = typeof window === 'undefined';
enableStaticRendering(isServer);

export const stores = {
	dashboardsStore,
	errorStore,
	messageStore,
	widgetsStore,
};
