// Dependencies
import { action, makeAutoObservable } from 'mobx';
import hubClient from '../hubClient';
import { delayUntil } from '../helpers/delay';

class DashboardsStore {
	loading = true;
	error = null;
	dashboards = [];

	constructor() {
		makeAutoObservable(this);
	}

	// This is used to process messages for the 'activity-updates' channel.
	// It would be great to have a way to chain multiple events here easily
	processActivityUpdates = action((message) => {
		if (message.event === 'dashboard-deleted') {
			const { metadata } = message;
			const filter = (d) => d.id !== metadata.id;
			this.dashboards = this.dashboards.filter(filter);
		}

		if (message.event === 'dashboard-updated') {
			const { metadata } = message;
			const dashboard = this.dashboards.find(
				(d) => d.id === metadata.dashboard.id
			);
			dashboard.name = metadata.dashboard.name;
			dashboard.updated_at = metadata.dashboard.updated_at;
		}
	});

	// This can be called if the client has a clientId in order to make
	// subscribing work
	listenForUpdates = action(async () => {
		try {
			const channel = 'activity-updates';
			await delayUntil(() => hubClient.getClientId() !== null);
			await hubClient.subscribe(channel);
			hubClient.addChannelMessageHandler(
				channel,
				this.processActivityUpdates
			);
		} catch (err) {
			console.error(err);
			// NOTE - how do we handle an error on this?
			// Error notification?
		}
	});

	stopListeningForUpdates = action(async () => {
		const channel = 'activity-updates';
		hubClient.removeChannelMessageHandler(
			channel,
			this.processActivityUpdates
		);
		await hubClient.unsubscribe(channel);
	});

	getAll = action(async () => {
		try {
			const { dashboards } = await hubClient.rpc.send({
				action: 'get-dashboards',
			});
			this.loading = false;
			this.error = null;
			this.dashboards = dashboards;
		} catch (err) {
			this.loading = false;
			this.error = err;
			this.dashboards = null;
		}
	});

	update = action(async (data) => {
		try {
			const { error } = await hubClient.rpc.send({
				action: 'update-dashboard',
				data,
			});
			if (error) throw error;
		} catch (err) {
			this.loading = false;
			this.error = err;
		}
	});

	delete = action(async (id) => {
		try {
			await hubClient.rpc.send({
				action: 'delete-dashboard',
				data: { id },
			});
		} catch (err) {
			this.loading = false;
			this.error = err;
		}
	});
}

export default new DashboardsStore();
