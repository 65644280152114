// Dependencies
import { action, makeAutoObservable } from 'mobx';
import hubClient from '../hubClient';

class WidgetsStore {
	loading = true;
	error = null;
	widgets = [];

	constructor() {
		makeAutoObservable(this);
	}

	// Fetches all of the widgets for the Dashboard
	getAll = action(async (dashboard_id) => {
		if (dashboard_id) {
			try {
				const { widgets } = await hubClient.rpc.send({
					action: 'get-widgets',
					data: { dashboard_id },
				});
				this.loading = false;
				this.error = null;
				this.widgets = widgets;
			} catch (err) {
				this.loading = false;
				this.error = err;
				this.widgets = null;
			}
		}
	});

	// Processes channel messages based on the event that occurs
	processWidgetUpdates = action((message) => {
		if (message.event === 'widget-update') {
			const { metadata } = message;
			this.updateWidgetData(metadata.id, metadata.data);
		}
	});

	// listens for updates on the channel
	listenForUpdates = action(async (id) => {
		const channel = `dashboard_${id}`;
		await hubClient.subscribe(channel);
		hubClient.addChannelMessageHandler(channel, this.processWidgetUpdates);
	});

	// Updates the widget data
	updateWidgetData = action((id, data) => {
		let widget = this.widgets.find((w) => w.id === id);
		if (widget) {
			let index = this.widgets.indexOf(widget);
			this.widgets[index].data = data;
		}
	});

	// Clears the list of widgets
	clear = action(() => {
		this.widgets = [];
	});

	// Removes the event channel listener, and unsubscribes from the dashboard channel
	stopListeningForUpdates = action(async (id) => {
		const channel = `dashboard_${id}`;
		hubClient.removeChannelMessageHandler(
			channel,
			this.processWidgetUpdates
		);
		await hubClient.unsubscribe(channel);
	});
}

export default new WidgetsStore();
