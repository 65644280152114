// Dependencies
import React from 'react';
import styles from './Notification.module.scss';

const Notification = ({ message, onClick }) => (
	<div role="status" className={styles.notification} onClick={onClick}>
		{message}
	</div>
);

export default Notification;
